import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../components/layout"
import H3 from "../utils/H3"
import H1 from "../utils/H1"
import Button from "../utils/Button"
import Logo from "../utils/Logo"
import { useForm, Controller } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion"
import Input from "../utils/Input"
import Checkbox from "../utils/Checkbox"
import RadioBox from "../utils/RadioButton"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { AiOutlineCheckCircle } from "@react-icons/all-files/ai/AiOutlineCheckCircle";
import Favicon from "../images/favicon.svg";
import Seo from "../components/seo"
const ErrorMessage = ({ children }) => (
    <div className="text-sm text-red" role="alert">
        {children}
    </div>
);
const PhoneError = ({ children }) => (
    <div className="my-2 text-xs text-red" role="alert">
        {children}
    </div>
);
const SubmitButton = ({ processing, error, children, disabled }) => (
    <button
        className={`relative w-9/12 flex bg-red px-4 py-3 md:mb-8 mb-2 leading-none ml-auto mr-auto rounded-md justify-center items-center text-white font-bold md:text-lg text-base uppercase cursor-pointer transition duration-0 hover:bg-mustard mt-4 ${error ? 'SubmitButton--error' : ''}`}
        type="submit"
        disabled={processing || disabled}
        id="schedule-my-demo"
    >
        {processing ? 'Processing...' : children}
    </button>
);
const BookPage = () => {
    const [error, setError] = useState(null);
    const [value, setValue] = useState();
    const [processing, setProcessing] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [checkedB, setCheckedB] = useState(true);
    const [checkedH, setCheckedH] = useState(false);
    const [checkedO, setCheckedO] = useState(false);
    const [status, setStatus] = useState(false);
    const [state] = useState({ data: { checked: "1" } });
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();
    const onError = (errors, e) => console.log(errors, e);
    const handleForm = async (data) => {

        if (data.phoneInputWithCountrySelect && isPossiblePhoneNumber(data.phoneInputWithCountrySelect) === false) {
            setPhoneError("Please enter a valid phone number");
        }
        else {
            const modifiedName = "invitee_full_name=" + data.name.replace(/\s+/g, '%20');
            const modifiedEmail = "invitee_email=" + data.email;
            const mailChimpBharatnatyam = !checkedB ? '' : 'Yes';
            const mailChimpHindustani = !checkedH ? '' : 'Yes';
            const mailChimpOthers = !data.others ? "" : data.others;
            setProcessing(true);
            addToMailchimp(data.email, { NAME: data.name, PHONE: data.phone, BHARAT: mailChimpBharatnatyam, HINDUSTANI: mailChimpHindustani, OTHERS: mailChimpOthers })
                .then(data => {
                    setStatus(true);
                    var win = window.open('https://calendly.com/radhikakathal/30mins?' + modifiedName + "&" + modifiedEmail, '_blank');
                    win.focus();
                })
                .catch((error) => {
                    console.log(error)
                    setProcessing(false);
                    setStatus(false);
                    setError("Sorry, there was an error submitting. Please try again later.")
                })
            reset();
        }
    };

    return (
        <Layout>
            <Seo title="Book a Free live Class Now" url="https://kaladhwani.com/book-free-trial" image={Favicon} />
            <div className="flex flex-col justify-center px-2 lg:py-2 py-8 items-center lg:min-h-bannerDesktop md:h-auto" style={{ backgroundColor: "#AE3B17" }}>
                <div className="lg:w-4/12 md:w-8/12 w-full min-h-500px bg-white md:px-8 px-4 pt-8 lg:pt-0 rounded-2xl flex flex-col justify-start items-end" style={{ boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.25)" }}>
                    <div className="lg:block hidden">
                        <Logo></Logo>
                    </div>
                    <div className="w-full h-full flex flex-col justify-start items-start">
                        <p className="text-lg font-normal" style={{ color: "#5E5E5E" }}>Get in touch</p>
                        <h1 className="font-bold text-2xl text-black mt-4">Book a <span className="text-red">FREE</span> live class. NOW!</h1>
                    </div>
                    {status ? (
                        <div className="w-full h-auto flex flex-col justify-center items-center relative m-auto p-12 left-0 top-0 z-10">
                            <div className="relative w-full h-full flex flex-col items-center justify-center cursor-pointer transition-all duration-500">
                                <AiOutlineCheckCircle size={50} color={"#4EB693"} className="mb-4"></AiOutlineCheckCircle>
                                <h1 className="mb-2 text-red font-bold md:text-3xl text-2xl ">
                                    Thank you!
                                </h1>
                                <H3 color="text-grey " className="font-normal text-center" bottom="mb-4">
                                    Your submission has been received. <br></br>Please check your email for further communication.
                                </H3>
                                <Button type={"internalLink"} to="/">Home</Button>
                            </div>
                        </div>
                    ) : (
                        <div className="flex md:flex-row flex-col justify-center w-full mt-6">
                            <div className="w-full flex flex-col justify-around lg:items-center md:items-end items-start md:mt-0 md:pb-0 pb-8 mt-4">
                                <form onSubmit={handleSubmit(handleForm, onError)} className="flex md:flex-row flex-col justify-center w-full"
                                    noValidate>
                                    <div className="w-full flex flex-col">
                                        <Input
                                            placeholderKey="Your Name here"
                                            labelKey="Name"
                                            type="text"
                                            {...register("name", {
                                                required: "Your Name (required)",
                                            })}
                                            errorKey={errors.name?.message}
                                            inputClassName="mb-0"
                                        />
                                        <Input
                                            placeholderKey="Your Email ID here"
                                            labelKey="Email"
                                            type="email"
                                            inputClassName="mb-0"
                                            {...register("email", {
                                                required: `${("Please provide your email address")}`,
                                                pattern: {
                                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: ("Please provide a valid email address"),
                                                },
                                            })}
                                            errorKey={errors.email?.message}
                                        />
                                        <label
                                            className="block text-black font-normal text-sm leading-none mb-1 mt-0"
                                        >
                                            Phone/Whatsapp
                                        </label>

                                        <Controller
                                            name="phone"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { onChange, value } }) => (
                                                <PhoneInput
                                                    preferredCountries={['us', 'ca', 'gb', 'in']}
                                                    country="in"
                                                    value={value}
                                                    onChange={onChange}
                                                    defaultCountry="in"
                                                    id="phone"
                                                    placeholder={"Enter mobile number"}
                                                    containerClass="w-full h-11 md:h-12 rounded-none"
                                                    inputClass="bg-lighterOrange text-black w-full border-darkGrey rounded-none border-1 h-11 md:h-full pl-6 placeholder:text-sm placeholder:text-gray-400 focus:outline-none"
                                                />
                                            )}
                                        />
                                        {errors["phone"] && (
                                            <PhoneError className="text-sm">{phoneError || "Invalid phone number"}</PhoneError>
                                        )}

                                        <div className="grid grid-cols-30-70 mt-8 md:gap-4 gap-8">
                                            <div className="w-full">
                                                <p className="mb-0 block text-black font-normal text-sm leading-none">Select the art
                                                    form</p>
                                            </div>
                                            <div className="w-full flex flex-wrap justify-start">
                                                <div onClick={() => setCheckedB(!checkedB)} className="cursor-pointer last:mr-0 mr-8">
                                                    <div className="flex items-center mr-2 mb-2">
                                                        <input
                                                            className="opacity-0 absolute h-4 w-4"
                                                            type="checkbox"
                                                            name="bharatnatyam"
                                                            value={true}
                                                            checked={checkedB}
                                                            onChange={e => {
                                                                setCheckedB(e.target.checked);
                                                            }}
                                                            {...register("bharatnatyam")}
                                                        />

                                                        <div className="bg-white border-1 border-darkGrey w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-darkGrey">
                                                            {checkedB ? <img src="/images/check-red.svg" className="hidden w-5 h-5 pointer-events-none"></img> : " "}
                                                        </div>

                                                        <label htmlFor="bharatnatyam" className="select-none text-xs">Bharatanatyam</label>
                                                    </div>
                                                </div>
                                                <div onClick={() => setCheckedH(!checkedH)} className="cursor-pointer last:mr-0 mr-8">
                                                    <div className="flex items-center mr-2 mb-2">
                                                        <input
                                                            className="opacity-0 absolute h-4 w-4"
                                                            type="checkbox"
                                                            name="hindustaniMusic"
                                                            value={true}
                                                            checked={checkedH}
                                                            onChange={e => {
                                                                setCheckedH(e.target.checked);
                                                            }}
                                                            {...register("hindustaniMusic")}
                                                        />

                                                        <div className="bg-white border-1 border-darkGrey w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-darkGrey">
                                                            {checkedH ? <img src="/images/check-red.svg" className="hidden w-5 h-5 pointer-events-none"></img> : " "}
                                                        </div>

                                                        <label htmlFor="hindustaniMusic" className="select-none text-xs">Hindustani Music</label>
                                                    </div>
                                                </div>
                                                <div onClick={() => setCheckedO(!checkedO)} className="cursor-pointer">
                                                    <div className="flex items-center mr-2 mb-2">
                                                        <input
                                                            className="opacity-0 absolute h-4 w-4"
                                                            type="checkbox"
                                                            name="others"
                                                            value={true}
                                                            checked={checkedO}
                                                            onChange={e => {
                                                                setCheckedO(e.target.checked);
                                                            }}
                                                            {...register("others")}
                                                        />

                                                        <div className="bg-white border-1 border-darkGrey w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-darkGrey">
                                                            {checkedO ? <img src="/images/check-red.svg" className="hidden w-5 h-5 pointer-events-none"></img> : " "}
                                                        </div>

                                                        <label htmlFor="others" className="select-none text-xs">Other Art form (Please specify)</label>
                                                    </div>
                                                </div>
                                                {checkedO ?
                                                    <div className="w-full">
                                                        <Input
                                                            placeholderKey="Other Art form"
                                                            type="text"
                                                            {...register("others", {
                                                                required: "Please provide an art form",
                                                            })}
                                                            errorKey={errors.others?.message}
                                                            inputClassName="mb-0 w-full"
                                                        />
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>

                                        {/* <div className="grid grid-cols-30-70 mt-8">
                                            <div className="w-full">
                                                <p className="mb-0 block text-black font-normal text-base leading-none w-9/12 ">Demo preference</p>
                                            </div>
                                            <div className="w-full flex flex-wrap justify-start">
                                                <RadioBox
                                                    {...register
                                                        (
                                                            ("demo"),
                                                            { required: 'Please Select an option' },
                                                        )
                                                    }
                                                    value="Online"
                                                    defaultChecked
                                                    labelKey="Online  (Video call)"
                                                />
                                                <RadioBox
                                                    {...register
                                                        (
                                                            ("demo"),
                                                            { required: 'Please Select an option' },
                                                        )
                                                    }
                                                    value="Offline"

                                                    labelKey="Offline (In-Person)"
                                                />
                                            </div>
                                        </div> */}


                                        {error && <ErrorMessage>{error.message}</ErrorMessage>}
                                        <SubmitButton processing={processing} error={error} disabled={false}>
                                            Schedule My Demo
                                        </SubmitButton>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Layout >
    )
}

export default BookPage
